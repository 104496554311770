import { useStaticQuery, graphql } from "gatsby"
import { Post, Category } from "../models/Post"
// type categories = "all" | "projekty" | "verejne-obstaravanie"

// interface Post {
//   id: string
//   excerpt: string
//   path: string
//   title: string
//   //   category: string
//   fluid: any
//   //   fixed: any
//   date?: string
//   categoryName?: string
// }
// export const categoryName = (category: categories): string => {
//   switch (category) {
//     case "projekty":
//       return "Projekty"
//     case "verejne-obstaravanie":
//       return "Verejné obstarávanie"
//     case "all":
//       return ""
//   }
// }
export const useWordpressPosts = (categories: string[] = []): Post[] => {
  const { allWordpressPost } = useStaticQuery(graphql`
    query MyWordpressPosts {
      allWordpressPost(sort: { fields: [date] }) {
        edges {
          node {
            id
            title
            date(formatString: "YYYY-MM-DDTHH:mm:ssZ")
            excerpt
            slug
            status
            template
            format
            categories {
              name
              slug
            }
            featured_media {
              source_url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000, maxHeight: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            acf {
              logos {
                logo {
                  title
                  localFile {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                      sizes {
                        aspectRatio
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return allWordpressPost.edges
    .filter(edge => {
      //posts without dates are not posts
      // if (!edge.node.frontmatter.date) return false
      if (categories.length == 0) return true
      const items = edge.node.categories.filter(category =>
        categories.includes(category.slug)
      )
      if (items.length > 0) return true

      return false
    })
    .map(
      (edge): Post => ({
        id: edge.node.id,
        excerpt: edge.node.excerpt,
        date: edge.node.date,
        path: `/post/${edge.node.slug}/`,
        title: edge.node.title,
        fluid:
          edge.node.featured_media &&
          edge.node.featured_media.localFile &&
          edge.node.featured_media.localFile.childImageSharp.fluid,
        // category: edge.node.frontmatter.category,
        categories: edge.node.categories.map(
          item =>
            ({
              name: item.name,
              slug: item.slug,
            } as Category)
        ),
        logos:
          edge.node.acf &&
          edge.node.acf.logos &&
          edge.node.acf.logos
            .filter(
              item =>
                item.logo &&
                item.logo.localFile &&
                item.logo.localFile.childImageSharp
            )
            .map(item => ({
              fluid: item.logo.localFile.childImageSharp.fluid,
              title: item.logo.title,
              aspectRatio:
                item.logo.localFile.childImageSharp.sizes.aspectRatio,
            })),
        // categoryName: categoryName(edge.node.frontmatter.category),
        // fluid: edge.node.frontmatter.featuredImage.childImageSharp.fluid,
        // fixed: edge.node.frontmatter.featuredImage.childImageSharp.fixed,
      })
    )
}
