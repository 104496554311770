import React, { Component, FC } from "react"
import Link from "gatsby-link"
import { Post } from "../models/Post"

const MyButton: FC<{ targetIndex: number; pageContext: any }> = ({
  targetIndex,
  pageContext,
  children,
}) => {
  const { pathPrefix, index } = pageContext
  const btnClassName =
    "py-1 px-3 m-1 text-gray-100 bg-gray-900 hover:bg-gray-800 rounded"
  const btnActiveClassName = "py-1 px-3 m-1 text-gray-100 bg-gray-600 rounded"
  const createLink = (prefix, index) => {
    let link = "/"
    if (prefix) link = "/" + prefix + "/"
    link = `${link}${index == 1 ? "" : index.toString()}`
    return link
  }

  const link = createLink(pathPrefix, targetIndex)
  console.log({ pathPrefix })
  console.log({ targetIndex })
  console.log({ link })
  return (
    <Link to={link}>
      <button
        className={index == targetIndex ? btnActiveClassName : btnClassName}
      >
        {children}
      </button>
    </Link>
  )
}

const GatsbyPagination = ({ pageContext }: { pageContext: any }) => {
  const {
    // group,
    // pathPrefix,
    // first,
    // last,
    // index,
    pageCount,
    // additionalContex,
  } = pageContext

  let indexes = []
  for (let i = 1; i <= pageCount; i++) {
    indexes.push(i)
  }

  return (
    <section className="w-full flex flex-wrap items-center justify-center">
      {indexes.map(index => (
        <MyButton
          targetIndex={index}
          pageContext={pageContext}
          key={`pagination-${index}`}
        >
          {index}
        </MyButton>
      ))}
    </section>
  )
}
export default GatsbyPagination
