import React, { FC, useState } from "react"
import moment from "moment"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { Post } from "../models/Post"
import GatsbyPagination from "./GatsbyPagination"

const filterPosts = (posts: Post[], pageContext: any) => {
  if (pageContext === undefined || pageContext === null) return posts
  const { index, pageCount, additionalContext } = pageContext
  const pageLength = additionalContext.pageLength
  const startIndex = (index - 1) * pageLength
  const endIndex = startIndex + pageLength - 1
  return posts.filter((item, index) => startIndex <= index && index <= endIndex)
}

const PostList: FC<{ posts: Post[]; pageContext?: any }> = ({
  posts,
  pageContext,
}) => {
  posts = filterPosts(posts, pageContext)
  return (
    <>
      <section className="flex flex-col">
        {posts.map(post => (
          <MyPost post={post} key={post.id}></MyPost>
        ))}
      </section>
      {pageContext && pageContext.pageCount > 1 && (
        <GatsbyPagination pageContext={pageContext} />
      )}
    </>
  )
}

const MyPost: FC<{ post: Post }> = ({ post }) => {
  return (
    <div className="flex flex-col sm:flex-row border border-grey-100 bg-white mt-2 p-3">
      {post.fluid && (
        <div className="flex-none">
          <Link to={post.path}>
            <Img
              fluid={post.fluid}
              className="object-cover object-center w-full h-48 mr-3 sm:w-48 sm:h-48 "
            />
          </Link>
        </div>
      )}
      <div className="w-full">
        <Link to={post.path}>
          <h2
            className="text-2xl"
            dangerouslySetInnerHTML={{ __html: post.title }}
          ></h2>
        </Link>
        <div
          className=""
          dangerouslySetInnerHTML={{ __html: post.excerpt }}
        ></div>
        <footer className="flex flex-cols items-center justify-end">
          {post.logos && (
            <div className="inline relative text-left m-2">
              {post.logos.map((logo, index) => {
                return (
                  <div
                    className="w-8 inline-block mr-2"
                    key={index}
                    style={{
                      height: "32px",
                      width: `${32 * logo.aspectRatio}px`,
                    }}
                  >
                    <Img
                      fluid={logo.fluid}
                      imgStyle={{ objectFit: "contain" }}
                      // className="inline-block"
                    />
                  </div>
                )
              })}
            </div>
          )}
          <span className="text-xs text-gray-500 pt-1 pb-1 inline">
            Kategória:
            {post.categories &&
              `${post.categories.map(item => ` ${item.name}`)} `}
            {/* {post.date && `Dátum: ${moment(post.date).format("DD.MM.YYYY")}`} */}
          </span>
        </footer>
      </div>
    </div>
  )
}

export default PostList
